import React from 'react';
import {
  ChipField, useGetList, useRecordContext,
} from 'react-admin';
import PropTypes from 'prop-types';

const MinerStateField = (props) => {
  const record = useRecordContext();
  const { data } = useGetList(
    'metrics',
    { pagination: { perPage: 1, page: 1 },
      sort: { field: 'timestampCreated', order: 'DESC' },
      filter: { serialId: record.serialId } },

  );
  const recordWithTimestampAsInteger = {
    [props.source]: data?.[0]?.state ?? 'not from mqtt',
  };
  return <ChipField {...props} record={recordWithTimestampAsInteger} />;
};

MinerStateField.propTypes = {
  source: PropTypes.string.isRequired,
};

export default MinerStateField;
