import React, { useMemo } from 'react';

import {
  Admin,
  Resource,
  CustomRoutes,
  fetchUtils,
  Layout,
  AppBar,
} from 'react-admin';
import { Route } from 'react-router';
import './AdminContainer.scss';
import { useAuthContext, usePermissions } from 'providers/AuthProvider';
import jsonServerProvider from 'ra-data-json-server';

import { getBackendApiUrl } from 'utils/api';
import KeyList from 'components/resources/Key/List';
import KeyShow from 'components/resources/Key/Show';
import KeyCreate from 'components/resources/Key/Create';
import KeyEdit from 'components/resources/Key/Edit';

import MetricList from 'components/resources/Metric/List';
import MetricShow from 'components/resources/Metric/Show';
import MetricCreate from 'components/resources/Metric/Create';
import MetricEdit from 'components/resources/Metric/Edit';

import MinerList from 'components/resources/Miner/List';
import MinerShow from 'components/resources/Miner/Show';
import MinerCreate from 'components/resources/Miner/Create';
import MinerEdit from 'components/resources/Miner/Edit';

import TransactionsList from 'components/resources/Transactions/List';
import TransactionsShow from 'components/resources/Transactions/Show';
import TransactionsCreate from 'components/resources/Transactions/Create';
import TransactionsEdit from 'components/resources/Transactions/Edit';

import RewardsList from 'components/resources/Reward/List';
import RewardsShow from 'components/resources/Reward/Show';
import RewardsCreate from 'components/resources/Reward/Create';
import RewardsEdit from 'components/resources/Reward/Edit';

import { Alert } from '@mui/material';
import VpnKey from '@mui/icons-material/VpnKey';
import QueryStats from '@mui/icons-material/QueryStats';
import AdUnits from '@mui/icons-material/AdUnits';
import Paid from '@mui/icons-material/Paid';

import logo from 'assets/logo.png';

const CustomAppBar = (props) => (
  <AppBar
    {...props}
    color="transparent"
  >
    {/* eslint-disable-next-line react/prop-types */}
    {props.children}
    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
      <img src={logo} width={100} alt="logo" />
      <p style={{ marginLeft: 20 }}>AIRO ADMIN PANEL</p>
    </div>
  </AppBar>
);

const AppLayout = (props) => (
  <Layout {...props} appBar={CustomAppBar} />
);

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = new window.Headers({ Accept: 'application/json' });
  }
  options.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(getBackendApiUrl(), httpClient);

const AdminContainer = () => {
  const authCtx = useAuthContext();

  const permissions = usePermissions();

  if (!permissions.length) {
    return (
      <Alert>Forbidden</Alert>
    );
  }

  const AvailableComponents = useMemo(() => [
    { component: KeyList, permissions: ['view'], key: 'KeyList' },
    { component: KeyShow, permissions: ['view'], key: 'KeyShow' },
    { component: KeyCreate, permissions: ['view', 'edit'], key: 'KeyCreate' },
    { component: KeyEdit, permissions: ['view', 'edit'], key: 'KeyEdit' },
    { component: MetricList, permissions: ['view'], key: 'MetricList' },
    { component: MetricShow, permissions: ['view'], key: 'MetricShow' },
    { component: MetricCreate, permissions: ['view', 'edit'], key: 'MetricCreate' },
    { component: MetricEdit, permissions: ['view', 'edit'], key: 'MetricEdit' },
    { component: MinerList, permissions: ['view'], key: 'MinerList' },
    { component: MinerShow, permissions: ['view'], key: 'MinerShow' },
    { component: MinerCreate, permissions: ['view', 'edit'], key: 'MinerCreate' },
    { component: MinerEdit, permissions: ['view', 'edit'], key: 'MinerEdit' },
    { component: TransactionsList, permissions: ['view'], key: 'TransactionsList' },
    { component: TransactionsShow, permissions: ['view'], key: 'TransactionsShow' },
    { component: TransactionsCreate, permissions: ['view', 'edit'], key: 'TransactionsCreate' },
    { component: TransactionsEdit, permissions: ['view', 'edit'], key: 'TransactionsEdit' },
    { component: RewardsList, permissions: ['view'], key: 'RewardList' },
    { component: RewardsShow, permissions: ['view'], key: 'RewardShow' },
    { component: RewardsCreate, permissions: ['view', 'edit'], key: 'RewardCreate' },
    { component: RewardsEdit, permissions: ['view', 'edit'], key: 'RewardEdit' },
  ].reduce((acc, item) => {
    const allowed = item.permissions.every((permissionItem) => permissions.includes(permissionItem));
    if (!allowed) {
      return acc;
    }
    return {
      ...acc,
      [item.key]: item.component,
    };
  }, {}), [permissions]);

  return (
    <Admin
      basename="/app"
      authProvider={authCtx.raAuthProvider}
      dataProvider={dataProvider}
      layout={AppLayout}
    >
      <Resource
        name="keys"
        list={AvailableComponents.KeyList}
        show={AvailableComponents.KeyShow}
        create={AvailableComponents.KeyCreate}
        edit={AvailableComponents.KeyEdit}
        icon={VpnKey}
      />
      <Resource
        name="metrics"
        list={AvailableComponents.MetricList}
        show={AvailableComponents.MetricShow}
        create={AvailableComponents.MetricCreate}
        edit={AvailableComponents.MetricEdit}
        icon={QueryStats}
      />
      <Resource
        name="miners"
        list={AvailableComponents.MinerList}
        show={AvailableComponents.MinerShow}
        create={AvailableComponents.MinerCreate}
        edit={AvailableComponents.MinerEdit}
        icon={AdUnits}
      />
      <Resource
        name="transactions"
        list={AvailableComponents.TransactionsList}
        show={AvailableComponents.TransactionsShow}
        create={AvailableComponents.TransactionsCreate}
        edit={AvailableComponents.TransactionsEdit}
        icon={Paid}
      />
      <Resource
        name="rewards"
        list={AvailableComponents.RewardList}
        show={AvailableComponents.RewardShow}
        create={AvailableComponents.RewardCreate}
        edit={AvailableComponents.RewardEdit}
        icon={QueryStats}
      />
      <CustomRoutes>
        <Route path="/my-profile" element={<div>My Profile</div>} />
      </CustomRoutes>
    </Admin>
  );
};

export default AdminContainer;
