import React from 'react';
import { Stack } from '@mui/material';

import {
  BooleanInput, FormTab, NumberInput, ReferenceInput, SelectInput, TabbedForm, TextInput,
} from 'react-admin';

const EditFormInner = () => {
  return (
    <TabbedForm>
      <FormTab label="General Settings">
        <Stack direction="row" spacing={2}>
          <Stack spacing={2} direction="column" alignItems="center">
            <ReferenceInput source="minerId" reference="miners">
              <SelectInput />
            </ReferenceInput>
            <NumberInput source="temperature" />
            <NumberInput source="pressure" />
            <NumberInput source="humidity" />
            <NumberInput source="co" />
          </Stack>
          <Stack spacing={2} direction="column" alignItems="center">
            <NumberInput source="co2" />
            <NumberInput source="dust1" />
            <NumberInput source="dust25" />
            <NumberInput source="dust10" />
            <NumberInput source="quality" />
          </Stack>
        </Stack>
      </FormTab>
    </TabbedForm>
  );
};

export default EditFormInner;
