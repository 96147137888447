import React from 'react';
import {
  ChipField, NumberField, Show, SimpleShowLayout, TextField,
} from 'react-admin';

import { Card, CardHeader, Stack } from '@mui/material';
import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const MinerShow = () => (
  <Show>
    <Stack p={2} spacing={2}>
      <Card raised>
        <CardHeader>
          Miner
        </CardHeader>
        <Stack direction="row" spacing={2}>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="name" label="Name" />
          </SimpleShowLayout>
        </Stack>
      </Card>
      <Stack direction="row" spacing={2} p={2}>
        <Stack spacing={2} maxWidth="250px">
          <SimpleShowLayout>
            <h3>General</h3>
            <TextField source="userId" label="User" />
            <TextField source="model" label="Model" />
            <TextField source="health" label="Health" />
            <ChipField source="serialId" label="Serial" />
          </SimpleShowLayout>
        </Stack>
        <Stack spacing={2} maxWidth="250px">
          <SimpleShowLayout>
            <h3>Balance</h3>
            <NumberField source="totalRewards" />
            <NumberField source="currentAIRO" label="Current AIRO" />
            <TextField source="wallet" label="Wallet" />
          </SimpleShowLayout>
        </Stack>
        <Stack spacing={2} maxWidth="250px">
          <SimpleShowLayout>
            <h3>Age</h3>
            <NumberField source="ageRate" label="Age Rate" />
            <DateFieldUnixtime source="connectDate" label="Connect Date" />
          </SimpleShowLayout>
        </Stack>
      </Stack>
    </Stack>
  </Show>
);

export default MinerShow;
