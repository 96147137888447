import React from 'react';
import {
  ChipField, NumberField, Show, SimpleShowLayout,
} from 'react-admin';

import { Card, CardHeader, Stack } from '@mui/material';
import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const MetricShow = () => (
  <Show>
    <Stack p={2} spacing={2}>
      <Card raised>
        <CardHeader>
          Metric
        </CardHeader>
        <Stack direction="row" spacing={2}>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <ChipField source="state" />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <ChipField source="accuracy" />
          </SimpleShowLayout>
        </Stack>
      </Card>
      <Stack direction="row" spacing={2} p={2}>
        <Stack spacing={2} maxWidth="250px">
          <h3>Environment</h3>
          <SimpleShowLayout>
            <NumberField source="temperature" />
            <NumberField source="pressure" />
            <NumberField source="humidity" />
            <NumberField source="aqi" />
          </SimpleShowLayout>
        </Stack>
        <Stack spacing={2} maxWidth="250px">
          <h3>Pollution</h3>
          <SimpleShowLayout>
            <NumberField source="co" />
            <NumberField source="co2" />
            <NumberField source="dust1" />
            <NumberField source="dust25" />
            <NumberField source="dust10" />
            <NumberField source="quality" />
          </SimpleShowLayout>
        </Stack>
      </Stack>
    </Stack>
  </Show>
);

export default MetricShow;
