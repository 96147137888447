import React from 'react';
import {
  ChipField,
  TextField,
  BooleanField,
  useRecordContext,
} from 'react-admin';
import PropTypes from 'prop-types';

const FieldFormatted = ({ format, type = 'text', ...props }) => {
  const record = useRecordContext();
  let recordFormatted = { [props.source]: record[props.source] };
  if (typeof record[props.source] === 'number') {
    recordFormatted = { [props.source]: `${record[props.source]}`.slice(0, 5) };
  } else if (typeof record[props.source] === 'string' && record[props.source].length > format) {
    recordFormatted = { [props.source]: `${record[props.source]
      .slice(0, 4)}...${record[props.source]
      .slice(record[props.source].length - 4)}` };
  }
  if (type === 'chip') {
    return <ChipField {...props} record={recordFormatted} />;
  }
  if (type === 'boolean') {
    return <BooleanField {...props} record={Boolean(recordFormatted)} />;
  }
  return (
    <TextField {...props} record={recordFormatted} />);
};

FieldFormatted.defaultProps = {
  type: 'text',
  format: 30,
};
FieldFormatted.propTypes = {
  source: PropTypes.string.isRequired,
  format: PropTypes.number,
  type: PropTypes.string,
};

export default FieldFormatted;
