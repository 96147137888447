import React from 'react';
import {
  DateTimeInput,
} from 'react-admin';
import PropTypes from 'prop-types';

const leftPad = (nb = 2) => (value) => ('0'.repeat(nb) + value).slice(-nb);
const leftPad4 = leftPad(4);
const leftPad2 = leftPad(2);
const convertDateToString = (value) => {
  if (!(value instanceof Date) || Number.isNaN(value.getDate())) return '';
  const yyyy = leftPad4(value.getFullYear());
  const MM = leftPad2(value.getMonth() + 1);
  const dd = leftPad2(value.getDate());
  const hh = leftPad2(value.getHours());
  const mm = leftPad2(value.getMinutes());
  return `${yyyy}-${MM}-${dd}T${hh}:${mm}`;
};
const DateInputUnixtime = (props) => (
  <DateTimeInput
    {...props}
    format={(date) => convertDateToString(new Date(date * 1000))}
    parse={(date) => new Date(date) / 1000}
  />
);

DateInputUnixtime.propTypes = {
  source: PropTypes.string.isRequired,
};

export default DateInputUnixtime;
