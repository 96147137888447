import React from 'react';
import {
  Datagrid, EditButton, List, ShowButton,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';
import FieldFormatted from '../../fields/FieldFormatted';
import MinerStateField from '../../fields/MinerStateField';

const MinerList = () => (
  <List>
    <Datagrid>
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <DateFieldUnixtime source="connectDate" label="Last connect at" showTime />
      <MinerStateField source="state" />
      <FieldFormatted source="name" label="Name" format={10} />
      <FieldFormatted source="health" label="Health" format={5} />
      <FieldFormatted source="model" label="Model" format={15} />
      <FieldFormatted
        source="serialId"
        label="Serial"
        format={24}
        type="chip"
      />
      <FieldFormatted
        source="wallet"
        label="Wallet"
        format={16}
        type="chip"
      />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export default MinerList;
