import React from 'react';
import { FormTab, TabbedForm } from 'react-admin';
import EditInputGroup from './EditInputGroup';

const EditFormInner = () => (
  <TabbedForm>
    <FormTab label="General Settings">
      <EditInputGroup />
    </FormTab>
  </TabbedForm>
);

export default EditFormInner;
