import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormContext } from 'react-hook-form';
import { NumberInput } from 'react-admin';
import { Stack } from '@mui/material';
import moment from 'moment';
import DateInputUnixtime from '../../fields/DateInputUnixTIme';

const WEEK_IN_S = window?.config?.REWARD_PERIOD || process.env.REACT_APP_REWARD_PERIOD;

console.log(WEEK_IN_S);
const EditInputGroup = () => {
  const { setValue, getValues } = useFormContext();
  const calcWeeklyReward = (total, totalWeeks) => {
    if (!total || !totalWeeks) setValue('weeklyReward', 0);
    else setValue('weeklyReward', +(total / totalWeeks).toFixed(5));
  };
  const calcWeeksLeft = (totalWeeks) => {
    if (!totalWeeks) setValue('weeksLeft', 0);
    else setValue('weeksLeft', totalWeeks);
  };
  const calcEndDate = (startDate, totalWeeks) => {
    if (!startDate || !totalWeeks) setValue('endDate', 0);
    else setValue('endDate', startDate + totalWeeks * WEEK_IN_S);
  };
  return (
    <Stack spacing={2} direction="row">
      <Stack spacing={2} direction="column" alignItems="center">
        <NumberInput
          source="total"
          onChange={async (e) => {
            calcWeeklyReward(e.target.value, getValues().totalWeeks);
          }}
        />
        <NumberInput
          source="totalWeeks"
          onChange={async (e) => {
            calcWeeksLeft(+e.target.value);
            calcWeeklyReward(getValues().total, e.target.value);
            calcEndDate(getValues().startDate, +e.target.value);
          }}
        />
      </Stack>
      <Stack spacing={2} direction="column" alignItems="center">
        <NumberInput source="weeklyReward" disabled />
        <NumberInput source="weeksLeft" disabled />
      </Stack>
      <Stack spacing={2} direction="column" alignItems="center">
        <DateInputUnixtime
          source="startDate"
          defaultValue={moment().unix()}
          onChange={(e) => calcEndDate(+e.target.value, getValues().totalWeeks)}
        />
        <DateInputUnixtime source="endDate" disabled />
      </Stack>
    </Stack>
  );
};

EditInputGroup.propTypes = {
};
export default EditInputGroup;
