import React from 'react';
import { Stack } from '@mui/material';

import {
  BooleanInput, FormTab, TabbedForm, TextInput, SelectInput,
} from 'react-admin';

const keyOptions = [
  { name: 'REWARD', id: 'reward' },
];

const textBlur = { color: 'transparent', textShadow: '0 0 8px rgba(0,0,0,0.5)' };

const fullWidth = { width: '100%' };

const EditFormInner = () => (
  <TabbedForm>
    <FormTab label="General Settings">
      <Stack spacing={2} direction="row" width="100%">
        <Stack spacing={2} direction="column" alignItems="center" width="40%">
          <TextInput required source="keyName" autoComplete="off" style={fullWidth} />
          <TextInput required type="password" source="keyPassword" autoComplete="new-password" style={fullWidth} />
          <SelectInput required source="type" choices={keyOptions} style={fullWidth} />
        </Stack>
        <Stack spacing={2} direction="column" alignItems="center" width="60%">
          <TextInput required multiline source="keyBody" inputProps={{ style: textBlur }} style={fullWidth} />
        </Stack>
      </Stack>
    </FormTab>
  </TabbedForm>
);

export default EditFormInner;
