import React, { useCallback, useMemo } from 'react';
import { Stack } from '@mui/material';
import { v4 as uuidV4 } from 'uuid';
import moment from 'moment';

import {
  FormTab, NumberInput, TabbedForm, TextInput,
} from 'react-admin';
import PropTypes from 'prop-types';
import DateInputUnixtime from '../../fields/DateInputUnixTIme';

const EditFormInner = ({ mode }) => {
  const getDefaultValues = useCallback(() => ({
    serialId: uuidV4(),
  }), []);
  const formProps = useMemo(() => {
    if (mode !== 'create') {
      return null;
    }
    return { defaultValues: getDefaultValues() };
  }, [mode]);

  return (
    <TabbedForm {...formProps}>
      <FormTab label="General Settings">
        <Stack direction="row" spacing={2}>
          <Stack spacing={2}>
            <TextInput source="name" />
            <TextInput source="model" />
            <TextInput source="serialId" disabled={mode === 'edit'} />
            <TextInput source="wallet" />
            <TextInput source="userId" />
            <NumberInput source="totalRewards" />
            <NumberInput source="currentAIRO" label="Current AIRO" />
            <DateInputUnixtime source="connectDate" defaultValue={moment().unix()} />
            <NumberInput source="ageRate" />
          </Stack>
        </Stack>
      </FormTab>
    </TabbedForm>
  );
};
EditFormInner.defaultProps = {
  mode: 'edit',
};

EditFormInner.propTypes = {
  mode: PropTypes.string,
};
export default EditFormInner;
