import React from 'react';
import {
  Show, SimpleShowLayout, NumberField, TextField, ChipField,
} from 'react-admin';

import { Card, CardHeader, Stack } from '@mui/material';
import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const TransactionLayout = () => (
  <Stack p={2} spacing={2}>
    <Card raised>
      <CardHeader>
        Metric
      </CardHeader>
      <Stack direction="row" spacing={2}>
        <SimpleShowLayout>
          <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <ChipField source="type" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <ChipField source="value" />
        </SimpleShowLayout>
      </Stack>
    </Card>
    <Stack direction="row" spacing={2} p={2}>
      <Stack spacing={2} maxWidth="250px">
        <h3>Reward fields</h3>
        <SimpleShowLayout>
          <NumberField source="ageRate" label="Age Rate" />
          <NumberField source="uptimeRate" label="Uptime Rate" />
          <NumberField source="metricCount" label="Weekly uptime (in seconds)" />
          <NumberField source="health" label="Miner health" />
          <NumberField source="reward" label="Maximum reward (for one miner)" />
        </SimpleShowLayout>
      </Stack>
      <Stack spacing={2} maxWidth="250px">
        <h3>Wallet Fields</h3>
        <SimpleShowLayout>
          <TextField source="from" />
          <TextField source="to" />
        </SimpleShowLayout>
      </Stack>
      <Stack spacing={2} maxWidth="250px">
        <h3>Error field</h3>
        <SimpleShowLayout>
          <TextField source="reason" />
        </SimpleShowLayout>
      </Stack>
    </Stack>
  </Stack>
);
const TransactionShow = () => (
  <Show>
    <TransactionLayout />
  </Show>
);

export default TransactionShow;
