import React from 'react';
import { FormTab, NumberInput, ReferenceInput, SelectInput, TabbedForm } from 'react-admin';
import { Stack } from '@mui/material';

const transactionsOptions = [
  { name: 'REWARD', id: 'REWARD' },
  { name: 'WITHDRAW', id: 'WITHDRAW' },
  { name: 'TOP-UP', id: 'TOP-UP' },
];
const EditFormInner = () => (
  <TabbedForm>
    <FormTab label="General Settings">
      <Stack direction="row" spacing={2}>
        <Stack spacing={2} direction="column" alignItems="center">
          <ReferenceInput source="minerId" reference="miners">
            <SelectInput />
          </ReferenceInput>
          <NumberInput source="value" />
          <SelectInput source="type" choices={transactionsOptions} />
        </Stack>
      </Stack>
    </FormTab>
  </TabbedForm>
);

export default EditFormInner;
