import React from 'react';
import {
  BooleanField, Datagrid, EditButton, FunctionField, List, ShowButton, TextField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const typeRender = (record) => record?.type?.split('-')?.[0];

const KeyList = () => (
  <List>
    <Datagrid>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <TextField source="keyName" label="Name" />
      <FunctionField render={typeRender} label="Key Type" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export default KeyList;
