import React from 'react';
import {
  Datagrid, List, NumberField, ShowButton, TextField, ChipField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const TransactionList = () => (
  <List>
    <Datagrid>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <ChipField source="status" label="Status" />
      <NumberField source="value" label="Value" />
      <TextField source="type" label="Type" />
      <ShowButton />
    </Datagrid>
  </List>
);

export default TransactionList;
