/* eslint-disable i18next/no-literal-string */
import axios from 'axios';

const authFrontendUrl = window?.config?.urlAuth || process.env.REACT_APP_URL_AUTH;
const authRedirectUrl = `${authFrontendUrl}/login`;

export const TOKEN_KEY = 'token';

const createStandardAxiosInstanceWithAuth = ({
  url,
  redirectUrl,
}) => {
  const axiosInstance = axios.create({
    baseURL: url,
    timeout: 30000,
  });

  axiosInstance.interceptors.request.use((config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.authorization = `Bearer ${localStorage.getItem(TOKEN_KEY)}`;
    return config;
  });
  axiosInstance.interceptors.response.use(null, (error) => {
    if (error?.response?.status === 401) {
      const redirectUrlWithCallback = `${redirectUrl}?callback_url=${window.location.href}`;
      window.location.href = redirectUrlWithCallback;
    }
    return Promise.reject(error);
  });

  return axiosInstance;
};

export const getBackendApiUrl = () => {
  const defaultApiUrl = window?.config?.apiUrlCore || process.env.REACT_APP_API_URL_CORE;
  const { apiUrlBackend = defaultApiUrl } = window.config || {};
  return `${apiUrlBackend}`;
};
export const getAuthApiUrl = () => {
  const defaultApiUrl = window?.config?.apiUrlAuth || process.env.REACT_APP_API_URL_AUTH;
  const { apiUrlAuth = defaultApiUrl } = window.config || {};
  return apiUrlAuth;
};

export const axiosBackendInstance = createStandardAxiosInstanceWithAuth({
  url: getBackendApiUrl(),
  redirectUrl: authRedirectUrl,
});

export const axiosAuthInstance = createStandardAxiosInstanceWithAuth({
  url: getAuthApiUrl(),
  redirectUrl: authRedirectUrl,
});

export const getMe = async () => {
  const { data } = await axiosAuthInstance.get('/api/auth/me');
  return data;
};
