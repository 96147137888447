import React from 'react';
import {
  ChipField, Datagrid, FunctionField, List, ReferenceField, ShowButton,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const MetricList = () => (
  <List>
    <Datagrid>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <ReferenceField source="minerId" reference="miners">
        <ChipField source="serialId" />
      </ReferenceField>
      <ChipField source="state" />
      <FunctionField
        label="Metrics"
        render={({
          temperature,
          pressure,
          humidity,
          aqi,
          co,
          co2,
          dust1,
          dust25,
          dust10,
          quality,
          accuracy,
        }) => [
          `TEMP=${temperature}`,
          `PRESS=${pressure}`,
          `HUMID=${humidity}`,
          `CO=${co}`,
          `CO2=${co2}`,
          `D1.0=${dust1}`,
          `D2.5=${dust25}`,
          `D10=${dust10}`,
          `AQI=${aqi}`,
          `Q=${quality}`,
          `ACC=${accuracy}`,
        ].join(', ')}
      />
      <ShowButton />
    </Datagrid>
  </List>
);

export default MetricList;
