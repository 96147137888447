import React from 'react';
import {
  Create,
} from 'react-admin';
import EditForm from './EditForm';

const RewardCreate = () => (
  <Create>
    <EditForm />
  </Create>
);

export default RewardCreate;
