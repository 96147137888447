import React from 'react';
import {
  Show, SimpleShowLayout, NumberField, useRecordContext,
} from 'react-admin';

import { Card, CardHeader, Stack } from '@mui/material';
import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const RewardLayout = () => (
  <Stack p={2} spacing={2}>
    <Card raised>
      <CardHeader>
        Reward
      </CardHeader>
      <Stack direction="row" spacing={2}>
        <SimpleShowLayout>
          <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <NumberField source="total" label="Total AIRO" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <NumberField source="weeksLeft" label="Weeks Left" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <NumberField source="maxWeeklyReward" label="MaxWeekly Reward" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <DateFieldUnixtime source="endDate" label="End date" />
        </SimpleShowLayout>
      </Stack>
    </Card>
    <Stack direction="row" spacing={2} p={2}>
      <SimpleShowLayout>
        <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
        <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      </SimpleShowLayout>
      <SimpleShowLayout>
        <NumberField source="total" label="Total distributed AIRO" />
        <NumberField source="totalWeeks" label="Total weeks in this period" />
        <NumberField source="weeksLeft" label="Weeks Left" />
      </SimpleShowLayout>
      <SimpleShowLayout>
        <NumberField source="weeklyReward" label="Weekly Reward" />
      </SimpleShowLayout>
      <SimpleShowLayout>
        <DateFieldUnixtime source="startDate" label="Start date" showTime />
        <DateFieldUnixtime source="endDate" label="End date" showTime />
      </SimpleShowLayout>
      <SimpleShowLayout>
        <DateFieldUnixtime source="startWeek" label="Week start date" showTime />
        <DateFieldUnixtime source="endWeek" label="Week end date" showTime />
      </SimpleShowLayout>
    </Stack>
  </Stack>
);
const RewardShow = () => (
  <Show>
    <RewardLayout />
  </Show>
);

export default RewardShow;
