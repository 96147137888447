import React from 'react';
import {
  Datagrid, EditButton, List, NumberField, ShowButton,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const RewardList = () => (
  <List>
    <Datagrid>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <NumberField source="total" label="Total AIRO" />
      <DateFieldUnixtime source="startWeek" label="Start Week" showTime />
      <DateFieldUnixtime source="endWeek" label="End Week" showTime />
      <NumberField source="weeksLeft" label="Weeks Left" />
      <DateFieldUnixtime source="endDate" label="End date" />
      <ShowButton />
    </Datagrid>
  </List>
);

export default RewardList;
