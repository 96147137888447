import React from 'react';
import {
  Edit,
} from 'react-admin';
import EditForm from './EditForm';

const MetricEdit = () => (
  <Edit>
    <EditForm />
  </Edit>
);

export default MetricEdit;
